<template>
  <div class="page">
    <div class="particular">
      <div class="left">当前房号</div>
      <div class="right">{{ form.chosenSpacePath }}</div>
    </div>
    <div class="particular">
      <div class="left">真实姓名</div>
      <div class="right">
        <input
          v-model="form.realName"
          type="text"
          placeholder="请填写真实姓名"
          :disabled="form.realName != ''"
        />
      </div>
    </div>
    <div class="particular">
      <div class="left">是否产权人</div>
      <div class="right">{{ format(form.identity) }}</div>
    </div>
    <div class="particular">
      <div class="left">手机号码</div>
      <div class="right">{{ form.mobileNum }}</div>
    </div>
    <!-- <div class="particular"></div> -->
    <p class="message" v-if="form.identity == 0 || form.identity == 1">
      上传房产证（请上传包含产权人姓名及房号信息的部分即可）
    </p>
    <v-upload
      v-if="form.identity == 0 || form.identity == 1"
      class="oItem-b_upload"
      :imgUrl.sync="form.houseownerCertPhoto"
      :activityPicture.sync="form.houseownerCertPhoto"
      :maxCount="1"
      ref="load"
    ></v-upload>
    <!-- <div class="particular"></div> -->
    <p class="message" v-if="form.identity == 1">
      上传结婚证证（请上传包含自己和配偶姓名的部分即可）
    </p>
    <v-upload
      v-if="form.identity == 1"
      class="oItem-b_upload"
      :imgUrl.sync="form.marriageCertPhoto"
      :activityPicture.sync="form.marriageCertPhoto"
      :maxCount="1"
      ref="load"
    ></v-upload>
    <div class="btn" @click="submit()">提交</div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import {
  userInfoUrl,
  applyHouseOwnerAuditUrl,
  getProvidedRealNameUrl,
} from "./api.js";
export default {
  name: "identityAuthentication",
  data() {
    return {
      form: {
        houseownerCertPhoto: "",
        marriageCertPhoto: "",
        identity: null,
        mobileNum: "",
        realName: "",
        chosenSpacePath: "",
        houseId: "",
      },
      userInfo: {},
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    this.getUserInfo();
    await this.getRealName();
    console.log(this.$route.query.type);
  },
  methods: {
    async getRealName() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getProvidedRealNameUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        this.form.realName = res.data;
      }
    },
    submit() {
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (!this.form.houseownerCertPhoto) {
        this.$toast("请上传房产证图片");
        return;
      }
      if (this.form.realName == "") {
        this.$toast("请输入真实姓名");
        return;
      }
      if (this.form.identity == 1 && !this.form.marriageCertPhoto) {
        this.$toast("请上传结婚证图片");
        return;
      }
      let params = {
        houseownerCertPhoto: this.form.houseownerCertPhoto,
        marriageCertPhoto: this.form.marriageCertPhoto,
        identity: this.form.identity,
        mobileNum: this.form.mobileNum,
        realName: this.form.realName,
        chosenSpacePath: this.form.chosenSpacePath,
        houseId: this.form.houseId,
        userId: this.userId,
      };
      this.$axios.post(`${applyHouseOwnerAuditUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast({ message: "提交成功！", duration: 700 });
          setTimeout(() => {
            if (Number(this.$route.query.type) == 1) {
              this.$router.push({
                name: "voteList",
              });
            } else if (Number(this.$route.query.type) == 2) {
              if (wx.miniProgram) {
                wx.miniProgram.navigateTo({
                  url: `/subPages/my/myRoom`,
                });
              }
            }
          }, 700);
        }
      });
    },
    // 获取用户信息
    async getUserInfo() {
      let params = {
        userId: this.userId,
      };
      const res = await this.$axios.get(userInfoUrl, {
        params,
      });
      if (res.code === 200) {
        this.form.mobileNum = res.data.mobile;
        this.form.chosenSpacePath = res.data.chosenSpacePath;
        this.form.houseId = res.data.chosenSpaceId;
        this.form.identity = res.data.identity;
      }
    },
    format(num) {
      if (num == 0) {
        return "产权人本人";
      } else if (num == 1) {
        return "产权人配偶";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  padding: 0 19px 0 25px;
  box-sizing: border-box;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
    box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
    border-radius: 16px;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    line-height: 66px;
    position: fixed;
    bottom: 126px;
    left: 50%;
    transform: translateX(-50%);
  }
  .message {
    margin: 20px 0;
    font-size: 32px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 50px;
  }
  .particular {
    width: 100%;
    height: 100px;
    // background-color: antiquewhite;
    display: flex;
    line-height: 100px;
    .left {
      font-size: 32px;
      color: rgba(0, 0, 0, 0.85);
      text-align-last: left;
    }
    .right {
      flex: 1;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.5);
      text-align: right;
      input {
        width: 100%;
        height: 100%;
        text-align: right;
        color: rgba(0, 0, 0, 0.5);
      }
      input:disabled {
        background-color: transparent !important;
      }
      input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
</style>
